import {apiSlice} from '..';
import {
  ICompleteProfileBody,
  TProfileResponse,
  TSearchParams,
  TSearchProfileResponse,
  TUpdateProfileBody,
  TUpdateProfileResponse,
} from './types';

import qs from 'query-string';

export const profileApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getMyProfile: builder.query<TProfileResponse, void>({
      query: () => 'user/profile/me',
      providesTags: ['MyProfile'],
    }),
    updateMyProfile: builder.mutation<
      TUpdateProfileResponse,
      TUpdateProfileBody
    >({
      query: body => ({
        url: 'user/profile/me',
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['MyProfile'],
    }),
    completeProfile: builder.mutation<
      TUpdateProfileResponse,
      ICompleteProfileBody
    >({
      query: body => ({
        url: 'user/profile/complete',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['MyProfile'],
    }),
    deleteMyProfile: builder.mutation<TProfileResponse, void>({
      query: () => ({
        url: 'user/profile/me',
        method: 'DELETE',
      }),
      invalidatesTags: ['MyProfile'],
    }),
    updateProfileImage: builder.mutation<TProfileResponse, FormData>({
      query: data => ({
        url: 'user/profile/image',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['MyProfile'],
    }),
    getUserProfile: builder.query<TProfileResponse, string>({
      query: id => `users/${id}/profile`,
      providesTags: ['SingleProfile'],
    }),
    searchProfile: builder.query<TSearchProfileResponse, TSearchParams>({
      query: ({page = 1, limit = 20, search = ''}) =>
        'users/discover/search?' + qs.stringify({page, limit, search}),
      providesTags: ['Search'],
    }),

    getMoreSearchProfile: builder.query<TSearchProfileResponse, TSearchParams>({
      query: ({page = 1, limit = 20, search = ''}) =>
        'users/discover/search?' + qs.stringify({page, limit, search}),
      onQueryStarted: async (arg, {dispatch, queryFulfilled}) => {
        const {data} = await queryFulfilled;

        dispatch(
          profileApi.util.updateQueryData(
            'searchProfile',
            {
              search: arg.search,
              page: 1,
              limit: 20,
            },
            draft => {
              console.log('found data');
              draft?.data?.users?.push(...data.data?.users);
              draft.data.meta = data.data.meta;
            },
          ),
        );
      },
    }),
  }),
});

export const {
  useGetMyProfileQuery,
  useSearchProfileQuery,
  useGetUserProfileQuery,
  useUpdateProfileImageMutation,
  useDeleteMyProfileMutation,
  useUpdateMyProfileMutation,
  useLazyGetMyProfileQuery,
  useCompleteProfileMutation,
} = profileApi;
