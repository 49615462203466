export const route_info = {
  content_management: {
    video_fun: '/super-admin/content-management/video/fun',
    video: '/super-admin/content-management/video',
    video_detail: '/super-admin/content-management/video/detail',
    video_detail_fun: '/super-admin/content-management/video/fun/detail',
    video_upload: '/super-admin/content-management/video/add',
    video_edit: '/super-admin/content-management/video/edit',
    video_edit_fun: '/super-admin/content-management/video/fun/edit',
    student_reply_view:
      '/super-admin/content-management/daily-challenge/:id/student-replies-view',
    quiz_template: '/super-admin/content-management/quiz-template',
    manage_quiz: '/super-admin/content-management/quiz/manage-quiz',
    question: '/super-admin/content-management/quiz-template/:id/question',
    daily_challenge: '/super-admin/content-management/daily-challenge',
    student_replies:
      '/super-admin/content-management/daily-challenge/:id/student-replies',
    integrations: '/super-admin/content-management/integrations',

    video_school: '/school/content-management/video',
    video_school_fun: '/school/content-management/video/fun',
    video_detail_school: '/school/content-management/video/detail',
    video_upload_school: '/school/content-management/video/add',
    video_edit_school: '/school/content-management/video/edit',
    student_reply_view_school:
      '/school/content-management/daily-challenge/:id/student-replies-view',
    quiz_template_school: '/school/content-management/quiz-template',
    manage_quiz_school: '/school/content-management/quiz/manage-quiz',
    question_school: '/school/content-management/quiz-template/:id/question',
    daily_challenge_school: '/school/content-management/daily-challenge',
    student_replies_school:
      '/school/content-management/daily-challenge/:id/student-replies',
    integrations_school: '/school/content-management/integrations',
  },
  school_management: {
    districtCrud: '/super-admin/school-management/district-crud',
    school: '/super-admin/school-management/school',
    schoolCrud: '/super-admin/school-management/school-crud',
    schoolView: '/super-admin/school-management/school-view',
    classroom: '/super-admin/school-management/classroom',
    classroomView: 'classroom/:id',
    teacher: '/super-admin/school-management/teacher',
    parent: '/super-admin/school-management/parent',
    school_details: 'school/:id',
    school_manage: '/school-management/school/:id',
    school_manageFilter: '/school-management/school/:id/:gradeId/:topicId',
  },
  district_management: '/super-admin/district-management',
  district_management_school: '/super-admin/district/:id/school',
  district_management_edit: '/super-admin/district-management/edit/:id',
  notification_management: '/super-admin/notification-management',
  district_details: '/district/:id',
  student_details: '/student/:studentId',
  user_management: '/super-admin/user-management',
  user_manage: '/school-leader/user-management',
  user_role: '/super-admin/user-role',
  user_session: '/super-admin/user-session',
  user_settings: '/super-admin/settings',
  auth_route: {
    base: '/',
    register: '/register',
    create_account: '/create-account',
    forgot_password: '/forgot-password',
    reset_password: '/reset-password',
    verify_email: '/verify-email',
    login: '/',
  },
  common: {
    overview: '/overview',
    setting: '/account-settings',
  },
  error: {
    error_page: '*',
    unauthorized: '/401',
    server_error: '/500',
  },
};
