import VerifyEmail from 'components/VerifyEmail';
import { useEffect } from 'react';
import {
  Navigate,
  useSearchParams,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { startLoading } from 'store/Slice/loadingSlice';
import { loginwithToken } from 'store/Thunk/authThunk';
import { useAppDispatch, useAppSelector } from 'store/store';

interface Props {
  children: JSX.Element;
  allowedRoles?: string[];
}

const ProtectedRoute = ({ children, allowedRoles }: Props) => {
  const userData: any = localStorage.getItem('userData');

  const isAuthenticated = JSON.parse(userData);

  // console.log("----------------------", userRole, allowedRoles)
  const dispatch: any = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const schoolId = window.location.pathname?.split('/school/') || [];

  useEffect(() => {
    if (searchParams.get('token')) {
      dispatch(startLoading());
      dispatch(
        loginwithToken({
          token: searchParams.get('token'),
          navigate,
          schoolId,
        }),
      );
      searchParams.delete('token');
      navigate(pathname);
    }
  }, [searchParams]);

  if (!Object.keys(isAuthenticated ? isAuthenticated : {}).length) {
    return <Navigate to={'/login'} replace />;
  } else {
    const userRole = isAuthenticated['type'];
    if (
      allowedRoles &&
      allowedRoles?.length > 0 &&
      allowedRoles.indexOf(userRole) === -1
    ) {
      return <Navigate to={'/'} replace />;
    } else {
      return (
        <>
          <VerifyEmail />
          {children}
        </>
      );
    }
  }
};

export default ProtectedRoute;
