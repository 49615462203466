import moment from 'moment-timezone';

export const getLocalDateTime = (
  date: string,
  format: string = 'MMMM D, YYYY, HH:mm',
) => {
  if (!date) {
    return null;
  }
  const d = new Date(date);
  // const timezone = getUserTimeZone(null);
  // if (timezone) {
  //   return moment.tz(d, timezone).format(format);
  // }
  return moment(d).format(format);
};

export const getAuthUser = () =>
  localStorage.getItem('userData')
    ? JSON.parse(localStorage.getItem('userData') || '')
    : null;

// display multiple dropdown values in view or listing page.
export const displayMultipleValue = (obj: any, key?: string) => {
  if (obj && obj.length > 0) {
    return obj
      .map((val: any) => (key ? val[key].name : val.name))
      .join(', ')
      .replace(/, ([^,]*)$/, ' and $1');
  }
  return '-';
};

export const getOptionLabel = (option: any) => {
  if (option?.sequenceNumber) {
    return `${option?.sequenceNumber || ''} - ${option?.name || ''}`;
  } else {
    return `${option?.name || ''}`;
  }
};

export const formatDate = (dateString: string) => {
  const date = moment(dateString);
  return date.format('D MMMM YYYY');
};
