import axios from 'axios';
import config from '../config/config';
import analytics from 'utils/analytics';
const API = axios.create({ baseURL: config.BASE_URL });

API.interceptors.request.use((req: any) => {
  if (localStorage.getItem('userData')) {
    const userData: any = localStorage.getItem('userData');
    const token: any = JSON.parse(userData).token;

    if (token) {
      req.headers.Token = token;
    }
    if (token) {
      req.headers.Authorization = `Bearer ${token}`;
    }
  }
  return req;
});

// Add response interceptor to handle success and error analytics
API.interceptors.response.use(
  (response: any) => {
    // Send success analytics log
    const endpoint = response.config.url || 'Unknown Endpoint';
    analytics.logEvent('API Request Success', {
      endpoint,
      status: response.status,
      type: 'api',
    });

    return response;
  },
  (error: any) => {
    // Send error analytics log
    const endpoint = error.config?.url || 'Unknown Endpoint';
    const status = error.response?.status || 'Unknown Status';
    analytics.logEvent('API Request error', { endpoint, status, type: 'api' });

    return Promise.reject(error);
  },
);

export default API;
