import { apiSlice } from '..';
import { NotificationsResponse, TSendNotificationBody } from './types';

export const profileApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getNotifications: builder.query<NotificationsResponse, void>({
      query: () => 'fcm-notifications',
      providesTags: ['Notification'],
    }),
    sendNotifications: builder.mutation<any, TSendNotificationBody>({
      query: (body) => ({
        url: 'fcm-notifications/send',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Notification'],
    }),
  }),
});

export const { useGetNotificationsQuery, useSendNotificationsMutation } =
  profileApi;
