import { Box, InputAdornment, TextField } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import MenuIcon from '@mui/icons-material/Menu';
import React, { useState } from 'react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DraggableProvided,
  DraggableStateSnapshot,
  DroppableProvided,
  DroppableStateSnapshot,
} from 'react-beautiful-dnd';
import { useSettings } from 'hooks/useSettings';
import { MathJax } from "better-react-mathjax";
// import Latex from 'react-latex-next';

function DragableOptions(props: any) {
  // State to hold the list items
  const [initialItems, setInitialItems] = React.useState([
    { id: 'A', content: '' },
    { id: 'B', content: '' },
    { id: 'C', content: '' },
    { id: 'D', content: '' },
  ]);
  const [items, setItems] = React.useState(initialItems);
  const [answerIndex, setAnswerIndex] = React.useState<any>(null);

  React.useEffect(() => {
    let TempAnswerIndex =
      props.options?.answer === 'a'
        ? 0
        : props.options?.answer === 'b'
        ? 1
        : props.options?.answer === 'c'
        ? 2
        : props.options?.answer === 'd'
        ? 3
        : null;

    setAnswerIndex(TempAnswerIndex);
  }, [props.options?.answer]);

  const getCharacterOption = (index: number) => {
    return index === 0
      ? 'A'
      : index === 1
      ? 'B'
      : index === 2
      ? 'C'
      : index === 3
      ? 'D'
      : null;
  };

  // Handler for drag and drop events
  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return; // Item was dropped outside a droppable area
    const { source, destination } = result;
    const newItems = [...items];
    const [removed] = newItems.splice(source.index, 1);

    newItems.splice(destination.index, 0, removed);
    setItems(newItems);
    props.handleDragChanges(newItems);
    if (answerIndex === source.index) {
      let indexToChar = getCharacterOption(destination?.index);

      setAnswerIndex(destination.index);
      props.handleChangeAnswer(indexToChar);
    } else {
      let tempNewIndexValue =
        answerIndex > source.index && answerIndex === destination.index
          ? answerIndex - 1
          : answerIndex < source.index && answerIndex === destination.index
          ? answerIndex + 1
          : answerIndex;
      setAnswerIndex(tempNewIndexValue);
    }
  };

  React.useEffect(() => {
    setItems([
      { id: 'A', content: props.options ? props.options?.optionA : '' },
      { id: 'B', content: props.options ? props.options?.optionB : '' },
      { id: 'C', content: props.options ? props.options?.optionC : '' },
      { id: 'D', content: props.options ? props.options?.optionD : '' },
    ]);
  }, [
    props?.options.optionA,
    props.options?.optionB,
    props.options?.optionC,
    props.options?.optionD,
  ]);

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="droppable">
        {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
          <Box {...provided.droppableProps} ref={provided.innerRef}>
            {items.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(
                  provided: DraggableProvided,
                  snapshot: DraggableStateSnapshot,
                ) => (
                  <Box
                    sx={{ p: '8px 0' }}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <Box>
                      <Box display={'flex'} sx={{ alignItems: 'center' }}>
                        <MenuIcon sx={{ pr: 1, fontSize: '24px' }} />
                        <Box>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'flex-end',
                            }}
                          >
                            {' '}
                            {
                              // if item.content contains "\\("
                              props.options?.type === 'math' &&
                              item.content?.includes('\\(') ? (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    border: '1px solid #E5E7EB',
                                    borderRadius: '8px',
                                    padding: '14px',
                                    backgroundColor: '#F5F5F5',
                                    width: {
                                      md: '41rem',
                                      xs: '100%',
                                      sm: '100%',
                                    },
                                  }}
                                >
                                  <InputAdornment position="start">
                                    <Box
                                      onClick={() => {
                                        props.handleChangeAnswer(
                                          getCharacterOption(index),
                                        );
                                      }}
                                      sx={{
                                        display: 'inline',
                                      }}
                                    >
                                      {answerIndex === index ? (
                                        <Box
                                          sx={{
                                            display: 'inline',
                                            cursor: 'pointer',
                                          }}
                                        >
                                          <DoneIcon
                                            sx={{
                                              backgroundColor: '#13BD38',
                                              color: '#fff',
                                              borderRadius: '4px',
                                            }}
                                          />
                                        </Box>
                                      ) : (
                                        <Box
                                          sx={{
                                            p: '4px 8px',
                                            backgroundColor: '#979C9E',
                                            borderRadius: '4px',
                                            display: 'inline',
                                            color: '#fff',
                                            cursor: 'pointer',
                                          }}
                                        >
                                          {getCharacterOption(index)}
                                        </Box>
                                      )}
                                    </Box>
                                  </InputAdornment>
                                  <MathJax>{item.content}</MathJax>
                                </Box>
                              ) : (
                                <TextField
                                  id="input-with-icon-textfield"
                                  variant="outlined"
                                  placeholder="Choice"
                                  value={item.content}
                                  name={`option${getCharacterOption(index)}`}
                                  onChange={props.handleChange}
                                  sx={{
                                    width: {
                                      md: '41rem',
                                      xs: '100%',
                                      sm: '100%',
                                    },
                                  }}
                                  size={'medium'}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <Box
                                          onClick={() => {
                                            props.handleChangeAnswer(
                                              getCharacterOption(index),
                                            );
                                          }}
                                          sx={{
                                            display: 'inline',
                                          }}
                                        >
                                          {answerIndex === index ? (
                                            <Box
                                              sx={{
                                                display: 'inline',
                                                cursor: 'pointer',
                                              }}
                                            >
                                              <DoneIcon
                                                sx={{
                                                  backgroundColor: '#13BD38',
                                                  color: '#fff',
                                                  borderRadius: '4px',
                                                }}
                                              />
                                            </Box>
                                          ) : (
                                            <Box
                                              sx={{
                                                p: '4px 8px',
                                                backgroundColor: '#979C9E',
                                                borderRadius: '4px',
                                                display: 'inline',
                                                color: '#fff',
                                                cursor: 'pointer',
                                              }}
                                            >
                                              {getCharacterOption(index)}
                                            </Box>
                                          )}
                                        </Box>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              )
                            }
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default DragableOptions;
