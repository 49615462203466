// ** MUI Imports
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

// ** Icons Imports
import MenuIcon from 'mdi-material-ui/Menu';

// ** Type Import

// ** Components
// import Autocomplete from '../../../layouts/components/Autocomplete'
import { Settings } from '@core/context/settingsContext';
import { Theme } from '@mui/material/styles';
import {
  Avatar,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Base } from 'assets';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { useLocation } from 'react-router-dom';
import UserDropdown from '@core/layouts/components/shared-components/UserDropdown';
import { useState } from 'react';
import useShowEmailOtp from 'icons/useShowEmailOtp';

interface Props {
  item: any;
  hidden: boolean;
  admin: boolean;
  settings: Settings;
  toggleNavVisibility: () => void;
  saveSettings: (values: Settings) => void;
}

const AppBarContent = (props: Props) => {
  // ** Props
  const { item, hidden, toggleNavVisibility, settings } = props;

  const location = useLocation();

  const textfieldHidden = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up('sm'),
  );

  const getTitle = () => {
    const itemObj = item.filter((obj: any) => obj.path == location.pathname);
    return itemObj[0]?.title || '';
  };

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box
        className="actions-left"
        sx={{ mr: 2, display: 'flex', alignItems: 'center' }}
      >
        {getTitle() && (
          <Typography className="title_text" variant="subtitle1" mr={4}>
            {getTitle()}
          </Typography>
        )}
        {hidden ? (
          <IconButton
            color="inherit"
            sx={{ ml: -2.75 }}
            onClick={toggleNavVisibility}
          >
            <MenuIcon />
          </IconButton>
        ) : null}

        {/* THIS IS SEARCH-APPBAR */}
        {/* {textfieldHidden ? (
          //remove
          <TextField
            placeholder="Search"
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              )
            }}
          />
        ) : null} */}

        {/* <Autocomplete hidden={hidden} settings={settings} />
        {/* <AutocompleteComponent hidden={hidden} settings={settings} />    */}
      </Box>
      <Box
        className="actions-right"
        sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}
      >
        <NotificationsIcon />

        {/* <Avatar alt="Eureka" src={Base} sx={{ ml: 4, width: 32, height: 32 }} />
        <Typography sx={{ ml: 2 }}>Eureka </Typography>
        <KeyboardArrowDownOutlinedIcon sx={{ ml: 2 }} /> */}
        {/* <LanguageDropdown settings={settings} saveSettings={saveSettings} /> */}
        {/* <ModeToggler settings={settings} saveSettings={saveSettings} />
        <NotificationDropdown settings={settings} /> */}
        <UserDropdown settings={settings} />
      </Box>
    </Box>
  );
};

export default AppBarContent;
