import {
  EventParams
} from 'firebase/analytics';
import { analytics as analyticsUtils } from 'store/firebase';

class analytics {
  static logEvent = (
    name: string,
    params:
      | {
          [key: string]: any;
          coupon?: EventParams['coupon'];
          currency?: EventParams['currency'];
          items?: EventParams['items'];
          payment_type?: EventParams['payment_type'];
          value?: EventParams['value'];
        }
      | undefined,
  ) => {
    if (name.includes('error')) {
      analyticsUtils.logEvent('exception', {
        description: name,
        fatal: true,
      });
      return;
    }
    analyticsUtils.logEvent(name, params);
  };
  static setUser = (userId: string | null) => {
    analyticsUtils.setUserId(userId || '');
  };
  static setScreenName = (screenName: string) => {
    analyticsUtils.logEvent('page_view', { screen_name: screenName });
  };
  static loginUser = (userId: string | null) => {
    this.setUser(userId);
    this.logEvent('login', { method: 'email' });
  };
  static logoutUser = () => {
    this.logEvent('logout', {});
  };
  static apiError = (error: any) => {
    this.logEvent('exception', { error });
  };
}

export default analytics;
