import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Stack,
  Link,
  Breadcrumbs,
  Card,
  Button,
  Icon,
  SelectChangeEvent,
} from '@mui/material';
import { ThreeDots } from 'react-loader-spinner';
import Latex from 'react-latex-next';

import QuestionDialog from 'components/dialog/question/AddQuestion';

import DeleteQuestion from 'components/dialog/question/Delete';
import RegenerateQuestion from 'components/dialog/question/Regenerate';
import UseGeminiResponse from 'components/dialog/question/UseGeminiResponse';

// *** Routes
import { route_info } from 'navigation/route';
import AddIcon from '@mui/icons-material/Add';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

// *** Third Party Imports
import { useNavigate, useParams } from 'react-router-dom';

// *** Custom Components
import AddQuestion from './addQuestion/index';
import AddUpdate from 'components/dialog/quiz/addUpdate';

// *** Icons
import EditWithBlackIcon from 'icons/editWithBlack';
import AddQuizEmptyIcon from 'icons/addQuizEmpty';
import BackButtonWithGreyBgIcon from 'icons/backButtonWithGreyBg';
import EditBlackIcon from 'icons/editBlack';
import EditWhiteIcon from 'icons/editWhite';

//**Stores
import { RootState, useAppDispatch, useAppSelector } from 'store/store';
import {
  createQuestion,
  generateQuestions,
  getQuestionList,
} from 'store/Thunk/questionThunk';
import { getQuizTemplateById } from 'store/Thunk/templateThunk';
import LoadingAI from '../LoadingAI';
import GenerateQuiz from 'components/dialog/quiz/generate';
import { setGeneratedQuestions } from 'store/Slice/QuestionSlice';
import { ArrowCircleLeft, ArrowCircleRight } from 'icons';
import ConfirmClose from 'components/dialog/question/ConfirmClose';
import OpenAiFail from 'components/dialog/question/OpenAiFail';

const blankFormData = {
  type: 'text',
  level: 'EASY',
  message: '',
  explanation: '',
  question: '',
  answer: '',
  optionA: '',
  optionB: '',
  optionC: '',
  optionD: '',
  optionAMedia: null,
  optionBMedia: null,
  optionCMedia: null,
  optionDMedia: null,
  questionMedia: null,
  createdById: '',
  templateIds: [''],
};
function Index() {
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState<any>(blankFormData);
  const [formDataGenerate, setFormDataGenerate] = useState<any>({
    grade: [],
    school: [],
    type: 'math',
  });
  const [isGenerateOpen, setIsGenerateOpen] = useState(false);
  const [generateLoading, setGenerateLoading] = useState(false);
  const [editId, setEditId] = useState('');
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openRegenerate, setOpenRegenerate] = React.useState(false);
  const [openConfirmClose, setOpenConfirmClose] = React.useState(false);
  const [openAiFail, setOpenAiFail] = React.useState(false);
  const [deleteId, setDeleteId] = useState('');
  // const [regenerateId, setRegenerateId] = useState('');
  const [currentQuestionId, setCurrentQuestionId] = useState('');
  const { id } = useParams();
  // const queryParams = new URLSearchParams(window.location.search);
  // const [questionId] = useState(queryParams.get('id'));
  const [isAddQuest, setIsAddQuest] = useState(false);
  const [loadPage, setLoadPage] = React.useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const navigate: any = useNavigate();
  const { generatedQuestions } = useAppSelector((state) => state.QuestionSlice);
  const [generatedQuestionsList, setGeneratedQuestionsList] = useState<any>([]);
  const { loadingAI } = useAppSelector((state) => state.QuestionSlice);
  const [questionList, setQuestionList] = useState<any>({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [questionType, setQuestionType] = useState<string>('text');
  const [questionMessage, setQuestionMessage] = useState<string>('');
  const questions = useAppSelector((state) => state.QuestionSlice);
  const [openUseGeminiResponse, setOpenUseGeminiResponse] = useState(false);
  const questionArray = [...generatedQuestionsList, ...questions.questionList];
  let updatedQuestionArray = questionArray.map((obj, index) => {
    // console.log('obj', obj);
    if (obj.questionId) {
      return { ...obj, saved: false, index };
    }
    return { ...obj, saved: true, index };
  });
  console.log('updatedQuestionArray', updatedQuestionArray);
  console.log('updatedQuestionArray', generatedQuestionsList);
  console.log('updatedQuestionArray.questions', questions);
  console.log('updatedQuestionArray', questions.questionList);

  useEffect(() => {
    setGeneratedQuestionsList([...generatedQuestions]);
  }, [generatedQuestions]);
  // useEffect(() => {
  //   if (currentQuestionId) {
  //     // set currentQuestionIndex as the index of regenerateId
  //     // set formData
  //     setCurrentQuestionIndex(updatedQuestionArray.findIndex((question:any) => question.id === currentQuestionId));
  //     setFormData(updatedQuestionArray[currentQuestionIndex]);
  //   }
  // }, [updatedQuestionArray]);
  const handleGenerateQuiz = () => {
    setIsGenerateOpen(!isGenerateOpen);
    !isGenerateOpen && setGenerateLoading(true);
  };
  const handleOpenAiFailDialog = (id: string) => {
    setOpenAiFail(!openAiFail);
  };
  const handleConfirmDialog = (id: string) => {
    setOpenConfirmClose(!openConfirmClose);
  };
  const handleDeleteDialog = (id: string) => {
    setOpenDelete(!openDelete);
  };
  const handleRegenerateDialog = (id: string) => {
    setOpenRegenerate(!openRegenerate);
  };
  const handleUseGeminiResponseDialog = (id: string) => {
    setOpenUseGeminiResponse(!openUseGeminiResponse);
  };
  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  // Use a function that takes no arguments to close the dialog
  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const { quizTemplate } = useAppSelector(
    (state: any) => state.templateReducer,
  );
  // useEffect(() => {
  //   setQuestionList(generateQuestions);
  // },[generateQuestions]);
  React.useEffect(() => {
    dispatch(
      getQuestionList({
        templateId: id,
      }),
    ).then((res: any) => {
      setQuestionList(res?.payload?.data?.data || []);
      setLoadPage(true);
    });
  }, [id]);
  useEffect(() => {
    dispatch(getQuizTemplateById({ id }));
  }, []);

  const handleFetch = (setPageOne: boolean) => {
    dispatch(
      getQuestionList({
        templateId: id,
      }),
    ).then((res: any) => {
      setQuestionList(res?.payload?.data?.data || []);
      setLoadPage(true);
    });
    dispatch(getQuizTemplateById({ id }));
  };
  React.useEffect(() => {
    if (questionList?.length > 0) {
      setIsAddQuest(true);
    }
  }, [questionList]);

  const handleAddUpdateQuiz = () => {
    setIsOpen(!isOpen);
  };

  const handleEditClick = (id: string) => {
    setIsOpen(!isOpen);
  };
  console.log('generatedQuestionsList', generatedQuestionsList);
  const handleBackButtonConfirm = () => {
    navigate(route_info.content_management.quiz_template);
    window.location.reload();
  };
  const handleBackButton = () => {
    generatedQuestionsList.length
      ? setOpenConfirmClose(true)
      : navigate(route_info.content_management.quiz_template);
  };
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const handleNext = () => {
    setCurrentQuestionIndex((prev: number) => prev + 1);
    if (formData?.question) {
      setFormData((prev: any) => updatedQuestionArray[prev.index + 1]);
    } else {
      setFormData((prev: any) => updatedQuestionArray[0]);
    }
  };
  const handlePrev = () => {
    setCurrentQuestionIndex((prev: number) => prev - 1);
    if (formData.question) {
      setFormData((prev: any) => updatedQuestionArray[prev.index - 1]);
    } else {
      setFormData((prev: any) => updatedQuestionArray[0]);
    }
  };
  const handleAddQuestionClick = () => {
    // handleOpenDialog();
    setIsAddQuest(true);
  };
  const { user } = useAppSelector((state: RootState) => state.authReducer);

  //
  const handleQuestionTypeChange = (event: SelectChangeEvent<string>) => {
    setQuestionType(event.target.value);
  };
  const transformedQuestionsList = generatedQuestionsList?.map(
    (question: any) => {
      const formData = {
        type: question.type || '',
        level: question.level || '',
        question: question.question || '',
        message: question.message || '',
        explanation: question.explanation || '',
        answer: question.answer || '',
        optionA: question.optionA || '',
        optionB: question.optionB || '',
        optionC: question.optionC || '',
        optionD: question.optionD || '',
        optionAMedia: null,
        optionBMedia: null,
        optionCMedia: null,
        optionDMedia: null,
        questionMedia: null,
        createdById: user?.id,
        templateId: id,
      };

      return formData;
    },
  );
  const handleQuestionMessageChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setQuestionMessage(event.target.value);
  };
  console.log('currentQuestionIndex', currentQuestionIndex);
  const saveAllQuestions = (event: any) => {
    // const [open,setOpen] = useState(false)
    event.preventDefault();
    setOpenConfirmClose(false);
    dispatch(
      createQuestion({
        _request: transformedQuestionsList,
        callback: () => {
          dispatch(
            getQuestionList({
              templateId: id,
            }),
          );
          setFormData({
            ...blankFormData,
          });
          dispatch(setGeneratedQuestions([]));
          setGeneratedQuestionsList([]);
        },
      }),
    );
  };
  return (
    <>
      {loadPage ? (
        <Box height={'100%'}>
          {
            <Box
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              bgcolor={'#FFF'}
              p={2}
              borderTop={'1px solid rgba(32, 165, 138, 0.08)'}
              position={'fixed'}
              zIndex={1100}
              minHeight={loadingAI ? '0px' : '70px'}
              width={'100%'}
            >
              <Stack
                direction={'row'}
                spacing={2}
                style={{ position: 'absolute', top: '-58px' }}
                alignItems={'center'}
              >
                <IconButton
                  onClick={handleBackButton}
                  sx={{ width: '54px', height: '54px' }}
                >
                  <BackButtonWithGreyBgIcon />
                </IconButton>
                <Typography
                  fontSize={24}
                  fontWeight={500}
                  color="#131515"
                  textTransform={'capitalize'}
                >
                  {quizTemplate?.title}
                </Typography>
              </Stack>
              {!loadingAI && (
                <Stack direction={'row'} spacing={2} alignItems={'center'}>
                  {quizTemplate.skill && (
                    <Typography
                      fontSize={24}
                      fontWeight={500}
                      color="#131515"
                      textTransform={'capitalize'}
                    >
                      {formData.question
                        ? `Question ${formData.index + 1}:`
                        : ''}{' '}
                      {quizTemplate?.skill[0]?.skill?.name}
                    </Typography>
                  )}
                  {/* <IconButton
                    onClick={() => setIsOpen(!isOpen)}
                    sx={{ width: '44px', height: '44px' }}
                  >
                    <EditWithBlackIcon />
                  </IconButton> */}

                  {updatedQuestionArray?.length > 1 && (
                    <Box className="flex items-center justify-center">
                      <IconButton
                        onClick={handlePrev}
                        disabled={formData.index === 0}
                      >
                        <ArrowCircleLeft />
                      </IconButton>
                      <Typography
                        fontSize={20}
                        fontWeight={500}
                        mx={2}
                        textTransform={'capitalize'}
                      >
                        {formData?.index + 1 || 0}/
                        {updatedQuestionArray?.length}
                      </Typography>
                      <IconButton
                        onClick={handleNext}
                        disabled={
                          updatedQuestionArray?.length === formData.index + 1
                        }
                      >
                        <ArrowCircleRight />
                      </IconButton>
                    </Box>
                  )}
                </Stack>
              )}
              <Stack direction={'row'} spacing={2}>
                {generatedQuestionsList?.length > 0 && !loadingAI && (
                  <Button
                    variant="contained"
                    className="primary_bg_btn"
                    onClick={saveAllQuestions}
                  >
                    Save All Questions
                  </Button>
                )}
                {/* {!loadingAI && (
                <Button
                  variant="contained"
                  className="primary_bg_btn"
                  endIcon={<EditWhiteIcon />}
                  onClick={() => {
                    setIsOpen(!isOpen);
                  }}
                >
                  Edit
                </Button>
              )} */}
                {/* {isAddQuest && !loadingAI && (
                  <Button
                    variant="contained"
                    className="green_bg_btn_bold"
                    sx={{ ml: 3 }}
                    onClick={handleGenerateQuiz}
                  >
                    Generate Question
                  </Button>
                )} */}
              </Stack>
            </Box>
          }

          {loadingAI ? (
            <LoadingAI />
          ) : !isAddQuest ? (
            <Box
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              flexDirection={'column'}
              height={'100%'}
              sx={{ pt: '1rem' }}
            >
              <IconButton>
                <AddQuizEmptyIcon />
              </IconButton>
              <Typography
                fontSize={18}
                fontWeight={500}
                color="#484848"
                marginBottom={5}
              >
                The question you add will show up here. Let's add your first
                question.
              </Typography>
              <Stack className="!flex-row gap-2">
                <Button
                  variant="contained"
                  className="primary_bg_btn"
                  endIcon={<AddIcon />}
                  onClick={() => handleAddQuestionClick()}
                >
                  Add question
                </Button>
                <Button
                  variant="contained"
                  className="green_bg_btn_bold"
                  sx={{ ml: 3 }}
                  onClick={handleGenerateQuiz}
                >
                  Generate Questions
                </Button>
              </Stack>
            </Box>
          ) : (
            <>
              <AddQuestion
                updatedQuestionArray={updatedQuestionArray}
                generatedQuestionsList={generatedQuestionsList}
                handleDeleteDialog={handleDeleteDialog}
                handleRegenerateDialog={handleRegenerateDialog}
                handleUseGeminiResponse={handleUseGeminiResponseDialog}
                setgeneratedQuestionsList={setGeneratedQuestionsList}
                setDeleteId={setDeleteId}
                dialogOpen={dialogOpen}
                handleOpenDialog={handleOpenDialog}
                handleCloseDialog={handleCloseDialog}
                quizTemplateData={quizTemplate?.title}
                questionType={questionType}
                questionMessage={questionMessage}
                handleQuestionTypeChange={handleQuestionTypeChange}
                handleQuestionMessageChange={handleQuestionMessageChange}
                formData={formData}
                setFormData={setFormData}
                setIsOpen={setIsOpen}
                setCurrentQuestionIndex={setCurrentQuestionIndex}
                currentQuestionId={currentQuestionId}
                setCurrentQuestionId={setCurrentQuestionId}
              />
            </>
          )}
        </Box>
      ) : null}
      {isOpen && (
        <AddUpdate editId={id} onClose={handleAddUpdateQuiz} open={isOpen} />
      )}
      {openDelete && (
        <DeleteQuestion
          setFormData={setFormData}
          setDeleteId={setDeleteId}
          open={openDelete}
          deleteId={deleteId}
          schoolId={id || ''}
          onClose={handleDeleteDialog}
          fetch={handleFetch}
          blankFormData={blankFormData}
        />
      )}
      {openRegenerate && (
        <RegenerateQuestion
          setFormData={setFormData}
          setQuestionId={setCurrentQuestionId}
          open={openRegenerate}
          questionId={currentQuestionId}
          schoolId={id || ''}
          onClose={handleRegenerateDialog}
          fetch={handleFetch}
          blankFormData={blankFormData}
        />
      )}
      {openUseGeminiResponse && (
        <UseGeminiResponse
          setFormData={setFormData}
          setQuestionId={setCurrentQuestionId}
          open={openUseGeminiResponse}
          questionId={currentQuestionId}
          schoolId={id || ''}
          onClose={handleUseGeminiResponseDialog}
          fetch={handleFetch}
          blankFormData={blankFormData}
        />
      )}
      
      {openConfirmClose && (
        <ConfirmClose
          setFormData={setFormData}
          setDeleteId={setDeleteId}
          open={openConfirmClose}
          deleteId={deleteId}
          onClose={handleConfirmDialog}
          onCancel={handleBackButtonConfirm}
          fetch={handleFetch}
          onSave={saveAllQuestions}
          blankFormData={blankFormData}
        />
      )}

      <OpenAiFail open={openAiFail} onClose={handleOpenAiFailDialog} />
      {isGenerateOpen && (
        <GenerateQuiz
          formData={formDataGenerate}
          setFormData={setFormDataGenerate}
          setOpenAiFail={setOpenAiFail}
          editId={editId}
          open={isGenerateOpen}
          onClose={handleGenerateQuiz}
          setQuestionList={setQuestionList}
        />
      )}
    </>
  );
}

export default Index;
