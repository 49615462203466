import SimpleLoader from '@core/components/MDLoader/simpleLoader';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import InfoIcon from 'icons/Info';
import { useState } from 'react';
import {
  deleteClassroom,
  getClassroomBySchoolID,
} from 'store/Thunk/classroomThunk';
import { RegenerateQuestion } from 'store/Thunk/questionThunk';
import { useAppDispatch } from 'store/store';

interface DialogProps {
  open: boolean;
  questionId: string;
  schoolId: string;
  setFormData: any;
  blankFormData: any;
  setQuestionId: any;
  onClose: (id: string) => void;
  fetch: (setPageOne: boolean) => void;
}

function Index(props: DialogProps) {
  const dispatch = useAppDispatch();
  const {
    onClose,
    blankFormData,
    questionId,
    setFormData,
    open,
    setQuestionId,
    schoolId,
    fetch,
  } = props;

  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    onClose('');
  };
  console.log(questionId);

  const onRegenerate = (id: string) => {
    console.log(id);
    setLoading(true);
    dispatch(RegenerateQuestion(id)).then((res) => {
      fetch(false);
      setLoading(false);
      console.log("res", res);
      handleClose();
      // setFormData({...res.payload})
      setFormData({
        ...blankFormData,
      });
      // setQuestionId('');
    });
  };

  return (
    <Dialog fullWidth maxWidth="sm" onClose={handleClose} open={open}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        py={6}
      >
        <InfoIcon />
        <Typography variant="h6" mt={3}>
          Are you sure you want to Regenerate this Question?
        </Typography>
        <Stack direction={'row'} spacing={4} mt={4}>
          <Button
            fullWidth
            size="small"
            variant="contained"
            className="dialog_grey_button"
            onClick={() => onClose('')}
          >
            No
          </Button>
          <Button
            fullWidth
            size="small"
            variant="contained"
            className="dialog_green_button"
            onClick={() => onRegenerate(questionId)}
            disabled={loading}
          >
            {loading ? <SimpleLoader size={24} color={'secondary'} /> : 'Yes'}
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
}

export default Index;
