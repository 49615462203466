import {
  Box,
  Button,
  Modal,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';

import Logo from '../../assets/sidebar_logo.png';

// @ts-ignore
import OTPInput from 'otp-input-react';
import {
  useResendEmailOtpMutation,
  useVerifyEmailOtpMutation,
} from 'store/apis/otp';
import { useGetMyProfileQuery } from 'store/apis/profile';

import { toast } from 'react-toastify';
import cn from 'utils/cn';

import { useFormik } from 'formik';

import * as Yup from 'yup';
import { useSendNotificationsMutation } from 'store/apis/notifications';

const validationSchema = Yup.object({
  title: Yup.string().required('Title is required'),
  body: Yup.string().required('Message is required'),
});

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export default function SendNotificationsModal({
  isOpen,
  onClose,
}: Readonly<Props>) {
  const [sendNotification, { isLoading }] = useSendNotificationsMutation();

  const formik = useFormik({
    initialValues: {
      title: '',
      body: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        toast.info('Sending Notification...', {
          position: 'top-right',
          autoClose: 3000,
        });

        await sendNotification(values).unwrap();


        toast.success('Notification sent successfully', {
          position: 'top-right',
          autoClose: 3000,
        });



        formik.resetForm();

        onClose();
      } catch (error: any) {
        toast.error(error?.data?.message, {
          position: 'top-right',
          autoClose: 3000,
        });
      }
    },
  });

  return (
    <>
      <Modal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ zIndex: 5000 }}
      >
        <form
          className="flex items-center justify-center flex-col
           bg-white p-8 w-[calc(100vw-1rem)] rounded-md max-w-xl gap-4 px-8 py-10 transform translate-x-[-50%] translate-y-[-50%] top-1/2 left-1/2 absolute
          "
          onPointerDown={(e) => e.stopPropagation()}
          onSubmit={formik.handleSubmit}
        >
          <Box className={''}>
            <img src={Logo} alt={'logo'} />
          </Box>

          <Stack className="space-y-5 flex items-center justify-center flex-col">
            <Typography
              className="!font-semibold !text-2xl sm:!text-2xl md:!text-2xl lg:!text-3xl"
              style={{ color: '#131515' }}
            >
              Send Notification
            </Typography>
          </Stack>

          <div className="py-8 grid w-full gap-4">
            <div className="space-y-1">
              <TextField
                placeholder="Title"
                error={
                  formik.touched.title && formik.errors.title ? true : false
                }
                {...formik.getFieldProps('title')}
                sx={{ width: '100%' }}
              />
              {formik.touched.title && formik.errors.title ? (
                <Typography
                  sx={{ color: 'red', fontSize: '12px', fontWeight: 500 }}
                >
                  {formik.errors.title}
                </Typography>
              ) : null}
            </div>

            <div className="space-y-1">
              <TextField
                placeholder="Message"
                error={formik.touched.body && formik.errors.body ? true : false}
                {...formik.getFieldProps('body')}
                sx={{ width: '100%' }}
              />
              {formik.touched.body && formik.errors.body ? (
                <Typography
                  sx={{ color: 'red', fontSize: '12px', fontWeight: 500 }}
                >
                  {formik.errors.body}
                </Typography>
              ) : null}
            </div>
          </div>

          <Button
            variant={'contained'}
            size="large"
            sx={{
              width: '60%',
              textTransform: 'capitalize',
              fontWeight: 500,
              fontSize: '20px',
              borderWidth: '2px',
            }}
            className={'primary_bg_btn'}
            onClick={() => formik.handleSubmit()}
            disabled={isLoading}
          >
            Continue
          </Button>
        </form>
      </Modal>
    </>
  );
}
