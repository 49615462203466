import { apiSlice } from '..';
import {
  TEmailOtpVerifyBody,
  TPhoneOtpVerifyBody
} from './types';

export const authApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    verifyEmailOtp: builder.mutation<any, TEmailOtpVerifyBody>({
      query: body => {
        return {
          url: '/auth/verify-email',
          method: 'POST',
          body: body,
        };
      },
      invalidatesTags: ['MyProfile'],
    }),
    verifyPhoneOtp: builder.mutation<any, TPhoneOtpVerifyBody>({
      query: body => {
        return {
          url: '/auth/verify-phone',
          method: 'POST',
          body: body,
        };
      },
    }),
    resendEmailOtp: builder.mutation<any, void>({
      query: () => {
        return {
          url: '/auth/resend-email',
          method: 'POST',
        };
      },
    }),
    resendPhoneOtp: builder.mutation<any, void>({
      query: () => {
        return {
          url: '/auth/resend-phone',
          method: 'POST',
        };
      },
    }),
  }),
});

export const {
  useVerifyEmailOtpMutation,
  useVerifyPhoneOtpMutation,
  useResendEmailOtpMutation,
  useResendPhoneOtpMutation,
} = authApi;
