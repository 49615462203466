import React from 'react';
import { useGetMyProfileQuery } from 'store/apis/profile';

export default function useShowEmailOtp() {
  const [type, setType] = React.useState<string | null>(null);
  const { data, isLoading } = useGetMyProfileQuery();
  // const isEmailVerified = data?.data?.isEmailVerified;
  const isEmailVerified = true;

  React.useEffect(() => {
    const header: any = localStorage.getItem('userData');
    const type: string = JSON.parse(header)?.type || '';

    setType(type);
  }, []);

  return !isEmailVerified && !isLoading && type !== 'super_admin';
}
