// ** React Imports
import { ReactNode } from 'react';

// ** MUI Imports
import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// ** Layout Imports
// !Do not remove this Layout import
import Layout from '../@core/layouts/Layout';

// ** Navigation Imports
import VerticalNavItems from '../navigation/vertical';
import HorizontalNavItems from '../navigation/horizontal';

// ** Component Import
// Uncomment the below line (according to the layout type) when using server-side menu
// import ServerSideVerticalNavItems from './components/vertical/ServerSideNavItems'
// import ServerSideHorizontalNavItems from './components/horizontal/ServerSideNavItems'

import VerticalAppBarContent from './components/vertical/AppBarContent';
import HorizontalAppBarContent from './components/horizontal/AppBarContent';

// ** Hook Import
import { useSettings } from '../@core/hooks/useSettings';
import useShowEmailOtp from 'icons/useShowEmailOtp';
import cn from 'utils/cn';

interface Props {
  noPadding?: boolean;
  admin?: boolean;
  role?: string;
  hiddenSidebar?: boolean;
  children: ReactNode;
}

const UserLayout = ({
  noPadding,
  children,
  admin,
  role,
  hiddenSidebar,
}: Props) => {
  // ** Hooks
  const { settings, saveSettings } = useSettings();
  const showOtpModal = useShowEmailOtp();

  /**
   *  The below variable will hide the current layout menu at given screen size.
   *  The menu will be accessible from the Hamburger icon only (Vertical Overlay Menu).
   *  You can change the screen size from which you want to hide the current layout menu.
   *  Please refer useMediaQuery() hook: https://mui.com/material-ui/react-use-media-query/,
   *  to know more about what values can be passed to this hook.
   *  ! Do not change this value unless you know what you are doing. It can break the template.
   */
  const hidden = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const mobileSize = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  return (
    <Layout
      noPadding={noPadding ? true : false}
      admin={admin ? true : false}
      hiddenSidebar={hiddenSidebar ? true : false}
      hidden={hidden}
      mobileSize={mobileSize}
      settings={settings}
      saveSettings={saveSettings}
      {...(settings.layout === 'horizontal'
        ? {
            // ** Navigation Items
            horizontalNavItems: HorizontalNavItems(),

            // Uncomment the below line when using server-side menu in horizontal layout and comment the above line
            // horizontalNavItems: ServerSideHorizontalNavItems(),

            // ** AppBar Content
            horizontalAppBarContent: () => (
              <HorizontalAppBarContent
                hidden={hidden}
                settings={settings}
                saveSettings={saveSettings}
              />
            ),
          }
        : {
            // ** Navigation Items
            verticalNavItems: VerticalNavItems(!!admin, role ? role : ''),

            // Uncomment the below line when using server-side menu in vertical layout and comment the above line
            // verticalNavItems: ServerSideVerticalNavItems(),

            // ** AppBar Content
            verticalAppBarContent: (props) => (
              <VerticalAppBarContent
                hidden={hidden}
                admin={true}
                settings={settings}
                item={VerticalNavItems(!!admin, role ? role : '')}
                saveSettings={saveSettings}
                toggleNavVisibility={props.toggleNavVisibility}
              />
            ),
          })}
    >
      <div
        className={cn({
          'mt-[70px] xl:!mt-[80px] z-40': showOtpModal,
        })}
      >
        {children}
      </div>
    </Layout>
  );
};

export default UserLayout;
