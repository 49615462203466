import { Box, Button, Card, Typography } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import { useLocation, useNavigate } from 'react-router-dom';
import { TTable } from 'components/Table/TableComponent';
import { Notifications } from 'store/apis/notifications/types';
import SendNotificationsModal from 'components/SendNotifications';
import { useState } from 'react';

const notificationTableData: TTable<Notifications>[] = [
      {
        label: 'Title',
        accessor: 'userId',
      }
];

function NotificationManagement() {
  const navigate = useNavigate();
  const location = useLocation();

  const [showSendNotificationModal, setShowSendNotificationModal] = useState(false);

  const handleAddClick = () => {
    setShowSendNotificationModal(true);
  };

  return (
    <Card sx={{ display: 'flex', gap: '20px', flexDirection: 'column', p: 5 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography fontWeight="500" fontSize="18px" color="#000">
          Notification Management
        </Typography>
        <Box sx={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
          {/* <Typography fontWeight="500" color="secondary">
            Download Sample
          </Typography> */}
          <Box sx={{ display: 'flex', gap: '10px' }}>
            {/* <Button
              endIcon={<FileUploadIcon style={{ color: '#000' }} />}
              variant="contained"
              className="gray_bg_btn"
            >
              Upload CSV
            </Button> */}
            <Button
              onClick={() => handleAddClick()}
              endIcon={<AddIcon style={{ color: '#ffff' }} />}
              variant="contained"
              className="primary_bg_btn"
            >
              Send Notification
            </Button>
          </Box>
        </Box>
      </Box>
      <SendNotificationsModal isOpen={showSendNotificationModal} onClose={() => setShowSendNotificationModal(false)} />
    </Card>
  );
}

export default NotificationManagement;
